<template>
    <div class="order-list" style="width: 100%; height: 100%">
            <!-- <div class="backBox" @click="back">
                <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconguanbi" />
                </svg>
            </div> -->
            <iframe
                style="border:none"
                height="100%"
                width="100%"
                ref='iframe'
            ></iframe>
    </div>
</template>

<script>
export default {
    name: "IframePage",
    components: {
       
    },
    computed: {
    },
    mixins: [],
    data() {
        return {
        };
    },
    mounted() {
        this.$refs.iframe.src = this.$route.query.url
    },
    created() {
    },
    watch: {
       
    },
    methods: {
        back() {
            this.$router.push('/orderList');
        },
        
    }
};
</script>

<style scoped lang="scss">

.backBox {
  position: fixed;
  width: 0.14rem;
  height: 0.14rem;
  top: .1rem;
  right: .2rem;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  z-index: 101;
  padding: 0.1rem;
  .icon {
    width: 1em;
    height: 1em;
    color: #313131;
    vertical-align: -0.015em;
    fill: currentColor;
    overflow: hidden;
    border-radius: 50%;
  }
}
</style>